.serviceCategoriesPage {
  .modal-header,
  .modal-footer{
    justify-content: center !important;
  }

  .textField input{
    font-size: 12px;
    padding: 8px 5px;
  }
  .textField label{
    font-size: 14px;
    padding: 5px 0;
  }

  .category_img {
    width: 30px !important;
    height: 30px !important;
  }

  .addBtn {
    float: right;
    margin: 10px 5px 20px 5px;
  }
}

.productsPage {
  margin-bottom: 60px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;

    .headerBoxes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow-x: auto;

      .box {
        position: relative;
        height: 110px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 5px;
        font-size: 16px;
        color: #858585;
        cursor: pointer;
        transition: all 0.5s ease;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          width: 1px;
          height: 60%;
          background-color: #4FC878;
        }

        &:last-child:after{
          display: none;
        }

        .icon {
          background-size: contain;
          background-position: 50% 50%;
          width: 35px;
          height: 35px;
          background-repeat: no-repeat;
          opacity: 0.6;
          transition: all 0.5s ease;
        }

        .copy {
          font-weight: 500;
          transition: all 0.5s ease;
        }

        &.active,
        &:hover {
          border-color: #009448;
          color: #009448 !important;


          .icon {
            opacity: 1;
          }

          .icon,
          .copy {
            transform: scale(1.1);
          }
        }
      }
    }

  }

  .productsContent {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 80px;

    .categories {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      gap: 20px;
      padding: 80px 0 30px 0;
      
      .category {
        position: relative;
        background-color: #009448;
        color: #fff;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        display: flex;
        align-items: center;
        
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -24px;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0 0 40px 25px;
          border-color: transparent transparent #009448 transparent;
          transform: rotate(0deg);
        }
      }
      
    }

    .companyCard {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      background-color: #fff;
      width: 150px;
      border-radius: 6px;
      margin: 0 20px 0 0;
      padding: 80px 20px 30px 20px;

      .logo {
        background-color: #fff;
        border-radius: 5px;
        width: 120px;
        height: 100px;
        position: absolute;
        top: -50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 1px 1px #eee;
        flex-direction: column;
        gap: 5px;

        .companyImg {
          width: 90%;
          height: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;
          border-radius: 5px;
        }

        .companyName {
          font-weight: 500;
          text-align: center;
        }
      }

      .companyProduct {
        display: flex;
        align-items: center;
        height: 40px;
        svg {
          color: #808080;
          font-size: 30px;
          transition: all 0.5s ease;
        }
        &:hover {
          transform: scale(1.1) !important;
        }
        &.active svg{
          color: #009448;
        }
      }

      .brochure {
        width: 100%;
        height: 80px;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          transform: scale(1.1) !important;
        }

        .brochureImg {
          width: 100%;
          height: 100%;
          background-position: 50%;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .cta {
        background-color: #d01f25;
        color: #fff;
        border: 2px solid #d01f25;
        padding: 5px 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: all 0.5s ease;

        cursor: pointer;
        font-weight: 500;

        &:hover {
          background-color: #fff;
          color: #d01f25;
        }
      }
    }

  }

}

.login-page {
    .card-body {
        padding: 2rem;
    }

    .auth_logo {
        position: relative;
        top: 0;
        width: auto;
        height: 90px;
        margin: 0 auto;
        text-align: center;
        line-height: 90px;
    }

    .languageDropdown.dropdown {
        position: absolute;
        top: 0;
        right: 10px;
        display: block;
        width: 70px;
        height: 70px;
        z-index: 9999;
    }

    .error_phone_login {
        font-size: 28px;
        text-align: center;
        color: #14946b;
    }

    .error_phone_login2 {
        text-align: center;
    }

    .error_phone_btn {
        position: relative;
        text-align: center;
        width: 100px;
        left: calc(50% - 50px);
        margin-top: 10px;
    }

    .reset_pass {
        position: relative;
        float: left;
        width: 100%;
        text-align: center;
        margin: 10px 0;
        cursor: pointer;
    }
    .reset_pass:hover {
        color: #14946b;
    }
}

.reset-page {
    .auth_logo {
        position: relative;
        top: 0;
        width: auto;
        height: 90px;
        margin: 0 auto;
        text-align: center;
        line-height: 90px;
    }
}

.verify-page {
    .auth_logo {
        position: relative;
        top: 0;
        width: auto;
        height: 90px;
        margin: 0 auto;
        text-align: center;
        line-height: 90px;
    }
    .toLogin {
        color: #009448;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        text-decoration: underline;
    }
}

.registration-page {
    margin-top: 50px;
    margin-bottom: 50px;

    .row {
        justify-content: center;
    }

    .card {
        border: 1px solid #000;
        min-height: 600px;
    }

    .card-title {
        border-bottom: 1px solid #B6B5B5;
        height: 60px;
        margin: 0;

        .steps {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 100%;
            cursor: pointer;
        }

        .step {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 18px;
            color: #7A7A7A;

            &.active {
                background-color: #009448;
                color: #fff;
            }
        }

    }

    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        margin: 0;

        &>div {
            width: 100%;
        }

        .titles {
            margin: 20px 0 20px 0;
            .title {
                font-size: 28px;
                font-weight: 500;
                color: #009448;
                text-align: center;
            }
            .secondTitle {
                font-size: 20px;
                color: #009448;
                text-align: center;
            }
        }

        .row {
            margin-bottom: 20px;
        }

        label {
            font-size: 16px;
            color: #009448;
            font-weight: 400;
        }

        input {
            border-radius: 20px;
            height: 30px;
        }

        button {
            position: absolute;
            width: auto;
            bottom: 25px;
            right: 30px;
            padding: 2px 30px;
            font-size: 16px;
            border-radius: 20px;
            float: right;

            &.cancel {
                right: 142px;
                background-color: #fff;
                color: #009448;
                border: 2px solid #009448;
                padding: 0 20px;
            }
        }

        .invalid-feedback {
            display: block;
            float: left;
            width: 100%;
        }

        .radioInputsRow {
            .radioCheck {
                position: relative;
                float: left;
                border: 1px solid #ced4da;
                border-radius: 20px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                margin-right: 20px;
                height: 30px;

                input {
                    height: 1em;
                    margin: 0;
                    border: 1px solid #009448;
                    background-color: #fff;
                    cursor: pointer;

                    &:checked {
                        background-color: #009448 !important;
                    }
                }

                label {
                    margin: 0;
                    color: #6F6F6F;
                    cursor: pointer;
                }
            }
        }

        .categoriesOption {
            display: flex;
            gap: 10px;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
            }
        }

        .plan {
            padding-top: 20px;
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            margin-bottom: 80px;

            .planHead {
                position: relative;
                float: left;
                width: 150px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                color: #009448;
                border-radius: 15px;
                font-size: 18px;
                gap: 20px;
                padding: 28px 0 0 0;
                margin: 0 10px 0 0;
                user-select: none;
            }

            .planCol {
                position: relative;
                float: left;
                width: 150px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                transition: all 0.5s ease-out;

                .planName {
                    color: #009448;
                    font-size: 18px;
                    text-align: center;
                    font-weight: 500;
                }

                .planItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    border-radius: 15px;
                    font-size: 18px;
                    gap: 20px;
                    padding: 20px 0 40px 0;
                    margin: 0 10px;
                    cursor: pointer;
                    transition: all 0.2s;
                    user-select: none;

                    svg {
                        transform: rotate(352deg);
                        font-size: 20px;
                    }
                }

                &:hover {
                    transform: scale(1.03);
                }

                &.selected {
                    transform: scale(1.1);
                }

                &.planCol_0 {
                    .planItem { background-color: #44DC8E; }
                    .planName { color:  #44DC8E; }
                    .item.meeting svg,
                    .item.personal svg{
                        visibility: hidden;
                    }
                }
                &.planCol_1 {
                    .planItem { background-color: #2CA869; }
                    .planName { color:  #2CA869; }
                    .item.personal svg{
                        visibility: hidden;
                    }
                }
                &.planCol_2 {
                    .planItem { background-color: #009448; }
                    .planName { color:  #009448; }
                }

            }

        }


        .periodContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            .periods {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                margin-bottom: 20px;

                .period {
                    background-color: #F3F3F3;
                    border-radius: 15px;
                    width: 220px;
                    display: flex;
                    flex-direction: column;
                    padding: 10px 15px;
                    color: #009448;
                    font-size: 16px;
                    position: relative;
                    cursor: pointer;

                    &.selected,
                    &:hover {
                        .check {
                            background-color: #009448;

                            svg {
                                display: block;
                            }
                        }
                    }

                    &.selected {
                        border: 2px solid #009448;
                    }

                }

                .title {
                    font-weight: 500;
                    font-size: 18px;
                }

                .check {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    background-color: #fff;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        display: none;
                        transform: rotate(352deg);
                        font-size: 14px;
                        color: #fff;
                    }
                }

                .discount {
                    position: absolute;
                    top: -10px;
                    right: -4px;
                    background-color: #009448;
                    color: #fff;
                    font-size: 12px;
                    padding: 0 5px;
                }

                .prices {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    .oldPrice {
                        text-decoration: line-through;
                        font-size: 14px;
                    }
                }
            }

            .copy {
                width: auto;
                color: #181818;
                font-size: 18px;
                text-align: center;
                padding-right: 25px;
            }

            .acceptTerms {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                font-size: 18px;
                padding-right: 20px;
                margin-top: 10px;

                input {
                    border-radius: 0;
                    height: 1em;
                    margin: 0;
                }
                label {
                    color: #181818;
                }
            }

        }

        .finishStepContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .finishedCopy {
                text-align: center;
                margin: 10px 0;
                font-size: 16px;
            }
            .finishedCopy2 {
                text-align: left;
                margin: 30px 50px 10px 0;
                font-size: 14px;
            }
            .toLogin {
                color: #009448;
                font-weight: 500;
                cursor: pointer;
            }
        }


        
    }
}


// error page

.error-page{
    text-transform: uppercase;
    font-size: 60px;
    line-height: .7;
    position: relative;
}
.greenStepAdminPage {

  .filters {
      position: relative;
      background-color: #fff;
      border-radius: 0.8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 70px;
      margin-bottom: 30px;

      .filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 25%;
        border-right: 1px solid;

        &.addNew{
          border-right: none;
        }

        span {
          font-weight: 700;
          font-size: 14px;
          padding-right: 10px;
          user-select: none;

        }
      }
    }

  .showVideo {
    padding: 0;
    cursor: pointer;

    svg {
      font-size: 22px;
    }
  }

  .react-switch-bg {
    text-align: center;
  }
  .irrelevant_checkbox {
    color: #fff;
    line-height: 26px;
  }
  .irrel_text {
    font-size: 12px;
    color: #666;
    font-weight: normal;
  }

}

.greenStepAdminPageAdd {
  .irrelevant_checkbox {
    color: #fff;
    line-height: 26px;
  }
  .irrel_text {
    font-size: 12px;
    color: #666;
    font-weight: normal;
  }
  .row {
    margin-bottom: 20px;
    .col {
      display: flex;
      flex-direction: column;
    }
  }
  textarea {
    border-radius: 0.8rem;
  }
}
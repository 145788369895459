.greenStepBigCategory {

  .card-body {
    padding: 40px 30px 40px 40px;
    font-size: 16px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }

  .cardDesc {
    margin-bottom: 20px;
  }

  .scoreAndFavoriteContainer {
    position: absolute;
    top: 9px;
    right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .statusIcon {
      padding: 0;
      width: 30px;
      height: 30px;
    }

    .showVideo {
      padding: 0;
      cursor: pointer;

      svg {
        font-size: 22px;
      }
    }

    .showProduct {
      font-weight: 600;
      cursor: pointer;
    }

    .score {
      padding: 0;
      margin-bottom: 0;
    }

    .favorite {
      margin-bottom: 0;
      cursor: pointer;

      .svg {
        cursor: pointer;
      }
    }

  }








  .quotation {
    font-style: italic;
    margin-top: 40px;
  }

  .favoriteCalendar {
    position: absolute;
    top: 10px;
    right: 40px;
    width: auto;
    height: auto;
    z-index: 99999;
    border: 1px solid #aeaeae;
    border-radius: 15px;
    background-color: #fff;

    .header {
      font-weight: 500;
      color: #009448;
      font-size: 16px;
      padding-left: 20px;
      padding-top: 10px;
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      padding-right: 20px;
      padding-bottom: 10px;

      .cancel {
        cursor: pointer;
        font-weight: 500;
      }

      .save {
        color: #009448;
        cursor: pointer;
        font-weight: 500;
      }
    }

    .react-datepicker {
      border-radius: 15px;
      border: none;

      .react-datepicker__header {
        height: 80px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #fff;
        //border-bottom: none;
      }

      .react-datepicker__month-select,
      .react-datepicker__year-select {
        padding: 3px 5px;
        border-radius: 20px;
        cursor: pointer;
      }

      .react-datepicker__navigation,
      .react-datepicker__current-month {
        display: none;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        border-radius: 0.3rem;
        color: #000;
      }

      .react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
        display: inline-block;
        margin: 0 20px 0 0;
      }
    }

}

  .userCommentRow {
    margin-top: 25px;
    margin-bottom: 10px;
    width: 100%;
    input {
      border-radius: 15px;
      padding: 5px 20px;
    }
  }

  .confirm_container {
    .textAreaBox {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      &.true textarea {
        border-color: red;
      }
      textarea {
        width: 400px;
        border-radius: 5px;
        border-color: #dad7d7;
        padding: 5px 10px;
        resize: none;
      }
    }

    .pdfUploaderContainer{
      margin-top: 20px;
      margin-bottom: 0;
      background-color: #fff;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .companyCompletedToggleBtns {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .companyCompleted {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 30px;
      user-select: none;
      margin-bottom: 8px;

      &.irrelevant {
        margin-bottom: 20px;
      }
    }

    &.irrelevant .companyCompleted.checkBox,
    &.notIrrelevant .companyCompleted.irrelevant {
      pointer-events: none;
      opacity: 0.5;
    }



    .companyCompletedTitle {
      font-size: 16px;
    }

    .react-custom-switch {
      width: 40px;
      height: 21px;
      margin-right: 20px;

      &.active {
        .react-switch-handle {
          transform: translateX(20px) !important;
        }
      }

      .react-switch-bg {
        height: 100% !important;
        width: 100% !important;
        opacity: 0.4;
        background: rgba(141, 134, 130, 0.7);
      }
      .react-switch-bg div {
        display: none !important;
      }
      .react-switch-handle {
        height: 21px !important;
        width: 22px !important;
        top: 0px !important;
        background-color: rgba(116, 105, 97, 0.5) !important;
      }
      .react-switch-handle div {
        height: 20px !important;
        width: 20px !important;
      }
      .react-switch-handle div svg {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  .carbonInGreenStepsContainer {
    .carbonBtns {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .btn {
        background-color: #4FC878;
        color: #fff;
        border-radius: 20px;
        height: 35px;
        line-height: 1;
        display: flex;
        align-items: center;
      }
    }

    .hide {
      display: none;
    }

    .ownCarbonContainer {
      .carbonItem {
        border: 1px solid #000;
        border-radius: 0.8rem;
        padding: 15px 20px 20px 20px;
        margin: 20px 0;

        .edit {
          float: right;
          cursor: pointer;
        }

        .year {
          font-weight: 500;
        }

        .scopes {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          color: #000;

          .scopeTitle {
            color: #858585;
            margin-bottom: 5px;
          }
        }
      }

    }

    .thirdPartyCarbonContainer {
      .carbonItem {
        border: 1px solid #000;
        border-radius: 0.8rem;
        padding: 15px 20px 20px 20px;
        margin: 20px 0;
        min-height: 250px;
        color: #000;

        .year {
          font-weight: 500;
          margin-bottom: 20px;
        }

        .carbonName {
          color: #858585;
          display: flex;
          align-items: center;
          gap: 30px;

          span {
            color: #000;
          }

          input {
            width: 50%;
          }
        }

        .scopes {
          margin-top: 10px;
          color: #000;
          height: 100px;

          .scope {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            width: 50%;
            float: left;
            margin: 10px 0;
          }

          .co2Text {
            white-space: nowrap;
          }

          .scopeTitle {
            color: #858585;
            margin-bottom: 0;
          }
        }

        .pdfContainer {
          float: left;
          margin-top: 10px;
        }

        .link {
          width: 100%;
          color: #858585;
          display: flex;
          align-items: center;
          gap: 30px;
          margin-top: 10px;

          a {
            width: 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          input {
            width: 50%;
          }
        }

        .footer {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-end;
          gap: 10px;

          .update {
            text-align: right;
            text-decoration: underline;
            cursor: pointer;
          }

          .delete {
            text-align: right;
            text-decoration: underline;
            cursor: pointer;
          }
        }


        input {
          height: 35px;
          width: 120px;
        }

        .pdfUploaderContainer {
          margin-top: 20px;
          margin-bottom: 0px;
          justify-content: center;

          .pdf_label {
            width: auto;
          }
        }
      }

      .scope3_data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;

        .companyCompletedToggleBtns {
          display: grid;
          grid-template-columns: max-content 1fr;
          width: 100%;
          height: auto;
          gap: 10px 50px;
          margin: 10px 0 20px 0;

          .companyCompleted {
            justify-content: flex-start;
          }
        }

        &.show {
          .companyCompletedIcon,
          .companyCompleted {
            pointer-events: none;
          }
        }

      }
    }

    .addThirdPartyCarbonContainer {
      margin: 40px 0;

      .year {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 20px;

        > div {
          width: 150px;
        }
      }

      .desc {
        margin-bottom: 20px;
      }

      .name {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 20px;

        input {
          width: 150px;
        }
      }

      .scope {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 20px;
        float: left;
        width: 50%;

        &.scope3 {
          width: 100%;
        }

        input {
          color: #000;
          width: 100px;
        }
      }

      .scope3_data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        .companyCompletedToggleBtns {
          display: grid;
          grid-template-columns: max-content 1fr;
          width: 100%;
          height: auto;
          gap: 10px 50px;
          margin: 10px 0 20px 0;

          .companyCompleted {
            justify-content: flex-start;
          }
        }
      }

      .pdf {
        margin-bottom: 20px;

        .pdfUploaderContainer {
          margin-top: 10px;
          margin-bottom: 10px;
          justify-content: center;

          .pdf_label {
            width: auto;
          }
        }
      }

      .link {
        margin-bottom: 20px;
        input {
          margin-top: 10px;
          background-color: #fff;
          width: 80%;
        }
      }

      input {
        line-height: 1 !important;
        border-radius: 20px !important;
        height: 35px;
      }

      .save {
        background-color: #4FC878;
        width: 100px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        float: right;
        position: absolute;
        right: 50px;
        cursor: pointer;
      }
    }

    .addCarbon {
      color: #4FC878;
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }

  }

  .customDropDownYear {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    label {
      position: relative;
      float: left;
      margin: 0 10px 0 0;
      font-weight: 400;
    }

    .react-select {
      position: relative;
      float: left;
      width: 150px;
      margin-right: 20px;
    }
  }

  .customGreenStep {
    margin: 20px 0;

    input:not(.css-mohuvp-dummyInput-DummyInput) {
      width: 100%;
      height: 35px;
    }

    .inputCol {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .companyCompletedIcon.remove {
      margin-top: 40px;
      cursor: pointer;
      svg {
        transform: rotate(0);
      }
    }
  }

  .saveRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .addMore {
      cursor: pointer;
    }
    .saveBtn {
      width: 100px;
      float: right;
      border-radius: 0.8rem;
      text-align: center;
      color: #fff;
      padding: 5px 20px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .customGreenStep178 {
    .confirm_container {
      margin-bottom: 40px;
    }
    .companyCompletedToggleBtns {
      margin-top: 10px;
    }
    .saveRow {
      .saveBtn {
        margin-top: 10px;
        width: 100px;
        float: right;
        border-radius: 0.8rem;
        text-align: center;
        color: #fff;
        padding: 5px 20px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .customGreenStep203 {
    .unGoalItem {
      margin-top: 50px;
    }

    .titleCopy {
      font-weight: 500;
    }
    .row  {
      margin-bottom: 25px;
    }

    .saveRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .addMore {
        color: #4433CD;
        cursor: pointer;
      }
      .saveBtn {
        width: 100px;
        float: right;
        border-radius: 0.8rem;
        text-align: center;
        color: #fff;
        padding: 5px 20px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .customGreenStep207 {
    .saveBtn {
      width: 100px;
      float: right;
      border-radius: 0.8rem;
      text-align: center;
      color: #fff;
      padding: 5px 20px;
      cursor: pointer;
      margin-top: 30px;
      white-space: nowrap;
    }
  }

  .customGreenStep211 {

  }

  .customGreenStep215 {

  }

  .customGreenStep216 {

  }

  .customGreenStep218 {

    .companyCompleted {
      margin: 10px 0;
      display: flex;
      align-items: center;
    }

    .companyCompletedTitle {
      color: #747474;
    }

    .subItems {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 40px;
      margin-bottom: 20px;

      .companyCompleted {
        width: 40%;
      }
    }

    .pdfContainer {
      gap: 10px;
      .link {
        margin-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .link {
      margin-top: 20px;
      input {
        margin-top: 10px;
        background-color: #fff;
        width: 80%;
        line-height: 1 !important;
        border-radius: 20px !important;
        height: 35px;
      }
    }

    .saveRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;

      .saveBtn {
        width: 100px;
        float: right;
        border-radius: 0.8rem;
        text-align: center;
        color: #fff;
        padding: 5px 20px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .customGreenStep219 {

    .row.addNewCertif {
      margin-top: 30px;
    }

    input {
      width: 100% !important;
    }

    .saveBtn {
      width: 100px;
      border-radius: 0.8rem;
      text-align: center;
      color: #fff;
      padding: 5px 20px;
      cursor: pointer;
      white-space: nowrap;
    }

  }

  .customGreenStep220 {

    .companyCompletedGroup {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .companyCompleted {
      margin: 10px 0;
      display: flex;
      align-items: center;
    }

    .companyCompletedTitle {
      color: #747474;
    }

    .saveRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;

      .saveBtn {
        width: 100px;
        float: right;
        border-radius: 0.8rem;
        text-align: center;
        color: #fff;
        padding: 5px 20px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .react-select.w150 {
    width: 150px !important;
  }

  .customRow {


    .customCol {

    }
  }
}
